import Image from "next/image";
import Link from "next/link";
import React, { useEffect } from "react";
import striptags from "striptags";
import right_arrow from "../../../public/assets/images/right-arrow.svg";
import handleViewport from "react-in-viewport";

const CaseStudy = (props) => {
  const caseStudyData = props.caseStudyData;
  const { forwardedRef, inViewport } = props;
  const data = props.data;

  useEffect(() => {
    console.clear();
  }, []);
  const titleWithoutPTag = caseStudyData?.value?.heading;
  return (
    <section ref={forwardedRef} className="case-studies-section">
      {inViewport && (
        <div className="case-studies-inner">
          <div className="container">
            <div className="row">
              <div className="col-12">
                <div className="case-studies-title">
                  <div className="section-heading">
                    <span
                      itemProp="heading"
                      dangerouslySetInnerHTML={{
                        __html: titleWithoutPTag,
                      }}
                    ></span>
                  </div>
                  {caseStudyData?.value?.case_studies_list_page_chooser?.map(
                    (val, index) => {
                      return (
                        <div key={index} className="section-heading-right">
                          <Link
                            href={val?.value?.page?.url}
                            itemProp="link"
                            className="font-size-18"
                          >
                            {val?.value?.heading}
                          </Link>
                        </div>
                      );
                    }
                  )}
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-12">
                <div className="div-grid-row">
                  {caseStudyData?.value?.case_studies?.map((val, ind) => {
                    return (
                      <div key={ind} className="div-grid-col">
                        <div className="casestudies-card">
                          <div className="casestudies-left">
                            <div className="casestudies-img">
                              {val?.value?.banner?.map((val, index) => {
                                if (val?.type == "image") {
                                  return (
                                    <Link key={index} href="#" itemProp="link">
                                      <Image
                                        src={val?.value?.url}
                                        alt={val?.value?.alt_text ?? 'digital-management-system'}
                                        width={500}
                                        height={500}
                                        loading="eager"
                                      />
                                    </Link>
                                  );
                                }
                              })}
                            </div>
                          </div>
                          <div
                            className="casestudies-right"
                            onClick={() => {
                              localStorage?.setItem(
                                "caseStudy",
                                val?.value?.slug
                              );
                            }}
                          >
                            <div className="casestudies-description">
                              <h3 itemProp="heading">
                                <Link
                                  href={`/case-studies/${val?.value?.slug}`}
                                  style={{ textDecoration: "none" }}
                                  dangerouslySetInnerHTML={{
                                    __html: val?.value?.title,
                                  }}
                                ></Link>
                              </h3>
                              {val?.value?.banner?.map((val, index) => {
                                if (val?.type == "about") {
                                  return (
                                    <p
                                      key={index}
                                      itemProp="description"
                                      dangerouslySetInnerHTML={{
                                        __html: val?.value?.description,
                                      }}
                                    ></p>
                                  );
                                }
                              })}
                            </div>
                            <div className="casestudies-button">
                              <Link
                                href={`/case-studies/${val?.value?.slug}`}
                                itemProp="link"
                                style={{ textDecoration: "none" }}
                              >
                                <Image
                                  width={25}
                                  height={25}
                                  src={right_arrow}
                                  alt="read-more"
                                  loading="eager"
                                />
                              </Link>
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </section>
  );
};

const CaseStudyViewportBlock = handleViewport(CaseStudy);

export default CaseStudyViewportBlock;
